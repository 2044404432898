/* eslint-disable */
import React, { useState } from 'react';
import JSPDF from 'jspdf';
import 'jspdf-autotable';
import _ from 'lodash';
import FrontImg from 'assets/VLC_BG.jpg';
import FrontImg2 from 'assets/VLC_BG_2.jpg';
import './customFont';
import ENVCONFIG from 'v2/config/config';

const renderLearningLevelsTable = (doc, subjects, startX, startY) => {
  const pageWidth = doc.internal.pageSize.width;
  const tableWidth = 140;
  const centeredStartX = (pageWidth - tableWidth) / 2 - 4;

  const cellHeight = 10;
  const cellWidths = [40, 35, 35, 35];
  let y = startY;

  const tableHeaders = ['Subjects', 'Advanced', 'Intermediate', 'Beginners'];

  doc.setFontSize(14);
  doc.setFont('Andika-Bold', 'bold');

  tableHeaders.forEach((header, index) => {
    const xPosition =
      centeredStartX + cellWidths.slice(0, index).reduce((a, b) => a + b, 0);
    doc.text(header, xPosition + cellWidths[index] / 2, y + 7, null, null, 'center');
    doc.line(xPosition, y + cellHeight, xPosition + cellWidths[index], y + cellHeight);

    if (index > 0) {
      const verticalLineX =
        centeredStartX + cellWidths.slice(0, index).reduce((a, b) => a + b, 0);
      doc.line(verticalLineX, y, verticalLineX, y + cellHeight);
    }
  });

  y += cellHeight;

  subjects.forEach((subject) => {
    const { subject_name, grade_dict } = subject;

    doc.setFontSize(10);
    doc.setFont('Andika-Bold', 'bold');

    const subjectXPosition = centeredStartX;
    doc.text(
      subject_name,
      subjectXPosition + cellWidths[0] / 2,
      y + 6.5,
      null,
      null,
      'center'
    );
    doc.line(
      subjectXPosition,
      y + cellHeight,
      subjectXPosition + cellWidths[0],
      y + cellHeight
    );

    doc.setFont('Andika-Regular', 'regular');
    grade_dict.forEach((grade, index) => {
      const xPosition =
        centeredStartX + cellWidths.slice(0, index + 1).reduce((a, b) => a + b, 0);

      // Highlight the selected learning level with an oval
      if (grade.isSelected) {
        const cellWidth = cellWidths[index + 1];
        const cellCenter = xPosition + cellWidth / 2;
        const ovalWidth = cellWidth * 0.8;
        const ovalHeight = cellHeight * 0.8;

        doc.setFillColor(255, 255, 255); // White background
        doc.ellipse(cellCenter, y + cellHeight / 2, ovalWidth / 2, ovalHeight / 2, 'F');

        doc.setTextColor(85, 19, 160); // Purple text color
      } else {
        doc.setTextColor(255, 255, 255); // White text color for non-selected
      }

      doc.text(
        grade.scale_name,
        xPosition + cellWidths[index + 1] / 2,
        y + 6.5,
        null,
        null,
        'center'
      );
      doc.setTextColor(255, 255, 255); // Reset text color to white
      doc.line(
        xPosition,
        y + cellHeight,
        xPosition + cellWidths[index + 1],
        y + cellHeight
      );

      if (index > 0) {
        const verticalLineX =
          centeredStartX + cellWidths.slice(0, index).reduce((a, b) => a + b, 0);
        doc.line(verticalLineX, y, verticalLineX, y + cellHeight);
      }
    });
    doc.line(141, y, 141, y + cellHeight);

    y += cellHeight;
  });

  doc.line(centeredStartX, y, centeredStartX + tableWidth, y);
};

const {
  s3: { ERP_BUCKET = '' },
} = ENVCONFIG;
export const VlcReportCardPdf = (reportCardData, principalSignData) => {
  const doc = new JSPDF({
    orientation: 'p',
    unit: 'mm',
    format: [210, 297],
    lineHeight: 1.3,
    letterSpacing: 2,
  });

  // First page
  var frontImg = new Image();
  frontImg.src = FrontImg;
  doc.addImage(frontImg, 'jpg', 0, 0, 210, 297);
  let y = 48;
  doc.setFontSize(18);
  doc.setFont('Andika-Bold', 'bold');
  doc.setTextColor(255, 255, 255);
  doc.text(`Volume Wise Learning Card`, 105, y, null, null, 'center');
  doc.setDrawColor(255);
  doc.setLineDash([1, 1]);
  doc.setFontSize(16);
  y += 18;
  doc.text(`Name: ${reportCardData?.erp?.name}`, 20, y, null, null, 'left');
  y += 2;
  doc.setFontSize(22);
  doc.line(38, y, 190, y);

  doc.setFontSize(16);
  y += 12;
  doc.text(`ERP Code:  ${reportCardData?.erp?.erp_id}`, 20, y, null, null, 'left');
  y += 2;
  doc.setFontSize(22);
  doc.line(50, y, 190, y);
  y += 2;

  doc.setFontSize(16);
  y += 12;
  doc.text(
    `Grade: ${reportCardData?.section_mapping?.grade_name}`,
    20,
    y,
    null,
    null,
    'left'
  );
  doc.text(
    `Section: ${reportCardData?.section_mapping?.section_name}`,
    74,
    y,
    null,
    null,
    'left'
  );
  doc.text(
    `Branch: ${reportCardData?.section_mapping?.branch_name}`,
    135,
    y,
    null,
    null,
    'left'
  );
  y += 2;
  doc.setFontSize(22);
  doc.line(40, y, 65, y);
  doc.line(98, y, 130, y);
  doc.line(158, y, 190, y);

  y += 19;
  doc.setLineDash([]);
  doc.setFontSize(22);
  doc.text(`EVENTS - VOLUME 1 & 2`, 105, y, null, null, 'center');
  y += 24;
  doc.setFontSize(22);
  doc.text(`Your ward has participated in:`, 105, y, null, null, 'center');

  y += 17;
  const startY = y;
  const subjects = reportCardData.report_card_json.subjects;
  let leftColumnY = y;
  let rightColumnY = y;

  subjects.forEach((subject, index) => {
    const column = index % 2;
    let currentY = column === 0 ? leftColumnY : rightColumnY;

    doc.setFontSize(18);
    doc.setFont('Andika-Bold', 'bold');
    doc.text(subject.subject_name, column === 0 ? 20 : 130, currentY, null, null, 'left');

    currentY += 8;
    doc.setFontSize(12);
    doc.setFont('Andika-Regular', 'regular');

    subject.level_dict.forEach((level) => {
      const boxX = column === 0 ? 20 : 130;
      const textX = column === 0 ? 26 : 136;

      doc.setLineWidth(0.2);
      doc.rect(boxX, currentY - 3, 3, 3);

      if (level.is_checked) {
        doc.setFillColor(255, 255, 255);
        doc.setLineWidth(0.5);
        doc.rect(boxX, currentY - 3, 3, 3, 'F'); // 'F' for fill
      }

      doc.text(level.subject_name, textX, currentY, null, null, 'left');
      currentY += 6;
    });

    if (column === 0) {
      leftColumnY = currentY + 6;
    } else {
      rightColumnY = currentY + 6;
    }

    if (Math.max(leftColumnY, rightColumnY) > 270) {
      doc.addPage();
      doc.addImage(frontImg, 'jpg', 0, 0, 210, 297);
      leftColumnY = 20;
      rightColumnY = 20;
    }
  });

  // Second page
  doc.addPage();
  var frontImg2 = new Image();
  frontImg2.src = FrontImg2;
  doc.addImage(frontImg2, 'jpg', 0, 0, 210, 297);

  doc.setFontSize(16);
  doc.setTextColor(255, 255, 255);
  doc.setFont('Andika-Bold', 'bold');

  doc.text(
    `Grade ${reportCardData.section_mapping.grade_name}`,
    105,
    10,
    null,
    null,
    'center'
  );

  const tableStartY = 16;
  const margin = 10;
  const pageWidth = doc.internal.pageSize.width;
  const tableWidth = (pageWidth - 3 * margin) / 2;

  let currentY = tableStartY;

  // Group subjects into pairs
  const subjectPairs = _.chunk(reportCardData.report_card_json.subjects, 2);

  subjectPairs.forEach((pair, pairIndex) => {
    let maxRows = 0;
    let rowHeights = [];

    // First pass: determine the maximum number of rows and row heights for the pair
    pair.forEach((subject) => {
      const tableData = [
        [subject.subject_name, 'LEARNING LEVELS'],
        ['The Student is able to', ''],
        ...subject.params_dict.map((param) => [param.param_name, '']),
      ];

      const heights = tableData.map((row) => {
        const textWidth = tableWidth * 0.73; // Adjust based on your column width
        const lines = doc.splitTextToSize(row[0], textWidth);
        return lines.length === 1 ? 3 : Math.max(6, lines.length * 3); // 4 for single line, 8 minimum for multi-line
      });

      maxRows = Math.max(maxRows, tableData.length);
      rowHeights = rowHeights.length
        ? rowHeights.map((h, i) => Math.max(h, heights[i] || 3))
        : heights;
    });

    // Second pass: create tables with equal height and row heights
    pair.forEach((subject, index) => {
      const tableData = [
        [
          {
            content: subject.subject_name,
            colSpan: 1,
            styles: { halign: 'left', fontStyle: 'bold' },
          },
          {
            content: 'LEARNING LEVELS',
            colSpan: 1,
            styles: { halign: 'center', fontStyle: 'bold', fontSize: 7 },
          },
        ],
        [
          {
            content: 'The Student is able to',
            colSpan: 2,
            styles: { halign: 'left', fontStyle: 'bold' },
          },
        ],
        ...subject.params_dict.map((param) => [
          { content: param.param_name, styles: { halign: 'left' } },
          { content: param.value, styles: { halign: 'center' } },
        ]),
      ];

      // Add empty rows to match the height of the taller table
      while (tableData.length < maxRows) {
        tableData.push([{ content: '', colSpan: 2 }]);
      }

      doc.autoTable({
        startY: currentY,
        margin: { left: margin + index * (tableWidth + margin), right: margin },
        tableWidth: tableWidth,
        body: tableData,
        theme: 'plain',
        tableLineColor: [255, 255, 255],
        tableLineWidth: 0.5,
        styles: {
          cellPadding: 1,
          fontSize: 9,
          valign: 'middle',
          overflow: 'linebreak',
          cellWidth: 'wrap',
          textColor: [255, 255, 255],
        },
        columnStyles: {
          0: { cellWidth: tableWidth * 0.7 },
          1: { cellWidth: tableWidth * 0.3 },
        },
        didParseCell: function (data) {
          data.cell.styles.minCellHeight = rowHeights[data.row.index];
        },
        didDrawCell: function (data) {
          if (data.section === 'body') {
            doc.setDrawColor(255);
            doc.setLineWidth(0.1);
            doc.line(
              data.cell.x,
              data.cell.y,
              data.cell.x + data.cell.width,
              data.cell.y
            );
            doc.line(
              data.cell.x,
              data.cell.y + data.cell.height,
              data.cell.x + data.cell.width,
              data.cell.y + data.cell.height
            );

            if (data.row.index !== 1 && data.column.index === 0) {
              doc.line(
                data.cell.x + data.cell.width,
                data.cell.y,
                data.cell.x + data.cell.width,
                data.cell.y + data.cell.height
              );
            }
          }
        },
      });
    });

    currentY = doc.previousAutoTable.finalY + 2;
    doc.setFont('Andika-Regular', 'regular');

    if (currentY > 250 && pairIndex < subjectPairs.length - 1) {
      doc.addPage();
      doc.addImage(frontImg2, 'jpg', 0, 0, 210, 297);
      currentY = tableStartY;
    }
  });

  renderLearningLevelsTable(doc, reportCardData.report_card_json.subjects, 30, 200);

  // Add signature space
  const signatureY = 290;
  doc.setFontSize(12);
  doc.setFont('Andika-Regular', 'regular');
  doc.setTextColor(255, 255, 255);

  doc.text("Principal's Signature: ", 20, signatureY);
  // Add principal's signature image
  if (principalSignData && principalSignData.length > 0) {
    const signatureImage = principalSignData[0].principle_sign;
    if (signatureImage) {
      const img = new Image();
      img.src = ERP_BUCKET + 'dev/media/' + signatureImage;

      try {
        doc.addImage(img, 'JPEG', 63, signatureY - 15, 40, 15);
      } catch {
        doc.line(63, signatureY, 100, signatureY);
      }
    } else {
      doc.line(63, signatureY, 100, signatureY);
    }
  } else {
    doc.line(63, signatureY, 100, signatureY);
  }

  doc.text("Parent's Signature: ", 110, signatureY);

  doc.line(150, signatureY, 190, signatureY);

  doc.setProperties({
    title: `${reportCardData?.erp?.name}- ${reportCardData?.erp?.erp_id}`,
  });

  return doc.output('bloburl');
};

export default VlcReportCardPdf;
