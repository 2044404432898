import React, { useState, useEffect } from 'react';
import {
  Form,
  Select,
  Input,
  Button,
  Card,
  Divider,
  Typography,
  Row,
  Col,
  Tooltip,
  message,
  Breadcrumb,
} from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import axiosInstance from 'config/axios';
import endpointsV2 from 'v2/config/endpoints';
import endpoints from 'config/endpoints';
import Layout from 'containers/Layout';
import { useSelector } from 'react-redux';

const { Option } = Select;
const { Title } = Typography;

const SportsReportConfig = () => {
  const selectedAcademicYear = useSelector(
    (state) => state.commonFilterReducer?.selectedYear
  );
  const [form] = Form.useForm();
  const [branchList, setBranchList] = useState([]);
  const [gradesList, setGradesList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedBranches, setSelectedBranches] = useState([]);

  useEffect(() => {
    fetchBranches({ session_year: selectedAcademicYear?.id });
  }, [selectedAcademicYear]);

  const fetchBranches = async (params = {}) => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(`${endpointsV2.academics.branches}`, {
        params: params,
      });
      setBranchList(response.data?.data?.results || []);
    } catch (error) {
      console.error('Error fetching branches:', error);
    }
    setLoading(false);
  };

  const handleBranchChange = (values, key) => {
    setSelectedBranches(values);
    fetchGrades({
      session_year: selectedAcademicYear?.id,
      branch_id: key?.map((each) => each.key)?.toString(),
    });
  };

  const fetchGrades = async (params = {}) => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(`${endpointsV2.academics.grades}`, {
        params: params,
      });
      setGradesList(response.data?.data || []);
    } catch (error) {
      console.error('Error fetching grades:', error);
    }
    setLoading(false);
  };

  const onFinish = (values) => {
    axiosInstance
      .post(endpoints?.sportsCardConfig.createSportsConfig, values)
      .then((res) => {
        message.success('Sports Report Template Created Successfully');
        form.resetFields();
        setSelectedBranches([]);
        setGradesList([]);
      })
      .catch((err) => {
        console.log({ err });
        message.error('Something went wrong');
      });
  };

  return (
    <Layout>
      <div className='row py-3 px-2 ' style={{ position: 'relative' }}>
        <div className='col-12'>
          <Breadcrumb separator='>'>
            <Breadcrumb.Item className='th-grey th-16' href='/assessment/report-config'>
              Assessment
            </Breadcrumb.Item>
            <Breadcrumb.Item className='th-black-1 th-16'>
              Sports Report Config
            </Breadcrumb.Item>
            <Breadcrumb.Item className='th-black-1 th-16'>
              Create Sports Report
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className='col-12 mt-3'>
          <Form
            className='th-bg-white p-3'
            form={form}
            name='sports_form'
            layout='vertical'
            onFinish={onFinish}
          >
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item
                  name='acad_sessions'
                  label='Branches'
                  rules={[
                    {
                      required: true,
                      message: 'Please select at least one branch',
                    },
                  ]}
                >
                  <Select
                    mode='multiple'
                    placeholder='Select branches'
                    onChange={handleBranchChange}
                    loading={loading}
                    showSearch
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {branchList.map((branch) => (
                      <Option key={branch.branch.id} value={branch.id}>
                        {branch.branch.branch_name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name='grades'
                  label='Grades'
                  rules={[
                    { required: true, message: 'Please select at least one grade' },
                  ]}
                >
                  <Select
                    mode='multiple'
                    placeholder='Select grades'
                    loading={loading}
                    showSearch
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {gradesList.map((grade) => (
                      <Option key={grade.id} value={grade.grade_id}>
                        {grade.grade_name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name='term_id'
                  label='Term'
                  rules={[{ required: true, message: 'Please select a term' }]}
                >
                  <Select placeholder='Select term'>
                    <Option value='1'>Semester 1</Option>
                    <Option value='2'>Semester 2</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Divider orientation='left' className='th-primary'>
              Levels
            </Divider>

            <Form.List name='level_dict' initialValue={false}>
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field, index) => (
                    <Row
                      key={field.key}
                      gutter={16}
                      align='middle'
                      style={{ marginBottom: 8 }}
                    >
                      <Col span={10}>
                        <Form.Item
                          {...field}
                          name={[field.name, 'level_name']}
                          rules={[{ required: true, message: 'Level name is required' }]}
                          noStyle
                        >
                          <Input placeholder='Level Name' />
                        </Form.Item>
                      </Col>
                      <Col span={10}>
                        <Form.Item
                          {...field}
                          name={[field.name, 'sequence']}
                          rules={[{ required: true, message: 'Sequence is required' }]}
                          noStyle
                        >
                          <Input type='number' placeholder='Sequence' />
                        </Form.Item>
                      </Col>
                      <Col span={4}>
                        <Tooltip title='Remove Level'>
                          <Button
                            type='link'
                            danger
                            onClick={() => remove(field.name)}
                            icon={<MinusCircleOutlined />}
                          />
                        </Tooltip>
                      </Col>
                    </Row>
                  ))}
                  <Form.Item>
                    <Button
                      type='dashed'
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add Level
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>

            <Divider orientation='left' className='th-primary'>
              Sports
            </Divider>

            <Form.List name='sports'>
              {(fields, { add: addSport, remove: removeSport }) => (
                <>
                  {fields.map((field, index) => (
                    <Card
                      key={field.key}
                      title={<Title level={5}>{`Sport ${index + 1}`}</Title>}
                      extra={
                        <Tooltip title='Remove Sport'>
                          <Button
                            type='link'
                            danger
                            onClick={() => removeSport(field.name)}
                            icon={<MinusCircleOutlined />}
                          />
                        </Tooltip>
                      }
                      style={{ marginBottom: 24 }}
                    >
                      <Row gutter={16}>
                        <Col span={16}>
                          <Form.Item
                            {...field}
                            name={[field.name, 'subject_name']}
                            rules={[
                              { required: true, message: 'Sport name is required' },
                            ]}
                            label='Sport Name'
                          >
                            <Input placeholder='Sport Name' />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            {...field}
                            name={[field.name, 'sequence']}
                            rules={[{ required: true, message: 'Sequence is required' }]}
                            label='Sequence'
                          >
                            <Input type='number' placeholder='Sequence' />
                          </Form.Item>
                        </Col>
                      </Row>

                      <Divider orientation='left' className='th-primary'>
                        Parameters
                      </Divider>
                      <Form.List name={[field.name, 'params_dict']}>
                        {(paramFields, { add: addParam, remove: removeParam }) => (
                          <>
                            {paramFields.map((paramField) => (
                              <Row
                                key={paramField.key}
                                gutter={16}
                                align='middle'
                                style={{ marginBottom: 8 }}
                              >
                                <Col span={18}>
                                  <Form.Item
                                    {...paramField}
                                    name={[paramField.name, 'param_name']}
                                    rules={[{ required: true, message: 'Name required' }]}
                                    noStyle
                                  >
                                    <Input placeholder='Parameter Name' />
                                  </Form.Item>
                                </Col>
                                <Col span={4}>
                                  <Form.Item
                                    {...paramField}
                                    name={[paramField.name, 'sequence']}
                                    rules={[
                                      { required: true, message: 'Sequence required' },
                                    ]}
                                    noStyle
                                  >
                                    <Input type='number' placeholder='Sequence' />
                                  </Form.Item>
                                </Col>
                                <Col span={2}>
                                  <Tooltip title='Remove Parameter'>
                                    <Button
                                      type='link'
                                      danger
                                      onClick={() => removeParam(paramField.name)}
                                      icon={<MinusCircleOutlined />}
                                    />
                                  </Tooltip>
                                </Col>
                              </Row>
                            ))}
                            <Form.Item>
                              <Button
                                type='dashed'
                                onClick={() =>
                                  addParam({ sequence: paramFields.length + 1 })
                                }
                                block
                                icon={<PlusOutlined />}
                              >
                                Add Parameter
                              </Button>
                            </Form.Item>
                          </>
                        )}
                      </Form.List>
                    </Card>
                  ))}
                  <Form.Item>
                    <Button
                      type='dashed'
                      onClick={() => addSport({ sequence: fields.length + 1 })}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add Sport
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>

            <Form.Item className='text-right'>
              <Button type='primary' htmlType='submit'>
                Submit
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </Layout>
  );
};

export default SportsReportConfig;
