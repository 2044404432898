import React, { useState, useEffect } from 'react';
import {
  Drawer,
  Select,
  Button,
  Spin,
  message,
  Collapse,
  Table,
  Input,
  Checkbox,
  InputNumber,
} from 'antd';
import axiosInstance from 'config/axios';

const { Option } = Select;
const { Panel } = Collapse;
const { TextArea } = Input;

const SportsMarksUpload = ({ visible, studentData, filterData }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [assessment, setAssessment] = useState({});
  const [selectedTerm, setSelectedTerm] = useState(null);
  const [remark, setRemark] = useState('');

  useEffect(() => {
    if (selectedTerm && filterData?.branch?.id && filterData.grade?.grade_id) {
      fetchTemplateAndExistingData();
    }
  }, [selectedTerm, filterData?.branch?.id, filterData.grade?.grade_id, studentData?.id]);

  useEffect(() => {
    if (!visible) {
      setData(null);
      setAssessment({});
      setSelectedTerm(null);
      setRemark('');
    }
  }, [visible]);

  useEffect(() => {
    if (!visible) {
      setData(null);
      setAssessment({});
      setSelectedTerm(null);
      setRemark('');
    }
  }, [visible]);

  const prefillAssessment = (existingData) => {
    const prefilled = { ...assessment };
    if (existingData?.report_card_json) {
      existingData.report_card_json.sports.forEach((sport, sportIndex) => {
        prefilled[sportIndex] = prefilled[sportIndex] || {
          parameters: {},
          levels: {},
          scores: {},
        };
        sport.params_dict.forEach((param, paramIndex) => {
          prefilled[sportIndex].parameters[paramIndex] = param.value;
        });
        sport.levels_dict.forEach((level, levelIndex) => {
          prefilled[sportIndex].levels[levelIndex] = level.score !== null;
          prefilled[sportIndex].scores[levelIndex] = level.score;
        });
      });
    }
    setAssessment(prefilled);
  };

  const fetchTemplateAndExistingData = async () => {
    setLoading(true);
    try {
      const templateResponse = await axiosInstance.get('/academic/sports-rc-template/', {
        params: {
          acad_session: filterData?.branch?.id,
          grade: filterData.grade?.grade_id,
          term_id: selectedTerm,
        },
      });
      setData(templateResponse.data.result);
      initializeAssessment(templateResponse.data.result);
      if (studentData?.id) {
        try {
          const reportCardResponse = await axiosInstance.get(
            '/academic/sports-report-card/',
            {
              params: {
                erp_id: studentData.id,
                term_id: selectedTerm,
                section_mapping: filterData?.section?.id,
              },
            }
          );
          if (reportCardResponse.data?.result) {
            prefillAssessment(reportCardResponse.data.result);
            setRemark(reportCardResponse.data.result.remark);
          }
        } catch (error) {
          console.error('Error fetching existing report card data:', error);
        }
      }
    } catch (error) {
      console.error('Error fetching template data:', error);
    } finally {
      setLoading(false);
    }
  };

  const initializeAssessment = (sports) => {
    const initialAssessment = {};
    sports.forEach((sport, sportIndex) => {
      initialAssessment[sportIndex] = {
        levels: {},
        parameters: {},
        scores: {},
      };
      sport.competition.forEach((level, levelIndex) => {
        initialAssessment[sportIndex].levels[levelIndex] = false;
        initialAssessment[sportIndex].scores[levelIndex] = null;
      });
      sport.sports_params.forEach((param, paramIndex) => {
        initialAssessment[sportIndex].parameters[paramIndex] = '';
      });
    });
    setAssessment(initialAssessment);
  };

  const handleLevelChange = (sportIndex, levelIndex, attended) => {
    setAssessment((prev) => ({
      ...prev,
      [sportIndex]: {
        ...prev[sportIndex],
        levels: {
          ...(prev[sportIndex]?.levels || {}),
          [levelIndex]: attended,
        },
        scores: {
          ...(prev[sportIndex]?.scores || {}),
        },
        parameters: {
          ...(prev[sportIndex]?.parameters || {}),
        },
      },
    }));
  };

  const handleScoreChange = (sportIndex, levelIndex, score) => {
    setAssessment((prev) => ({
      ...prev,
      [sportIndex]: {
        ...prev[sportIndex],
        scores: {
          ...(prev[sportIndex]?.scores || {}),
          [levelIndex]: score,
        },
        levels: {
          ...(prev[sportIndex]?.levels || {}),
        },
        parameters: {
          ...(prev[sportIndex]?.parameters || {}),
        },
      },
    }));
  };

  const handleParameterChange = (sportIndex, paramIndex, value) => {
    setAssessment((prev) => ({
      ...prev,
      [sportIndex]: {
        ...prev[sportIndex],
        parameters: {
          ...(prev[sportIndex]?.parameters || {}),
          [paramIndex]: value,
        },
        levels: {
          ...(prev[sportIndex]?.levels || {}),
        },
        scores: {
          ...(prev[sportIndex]?.scores || {}),
        },
      },
    }));
  };

  const prepareSubmissionData = () => {
    const sports = data.map((sport, sportIndex) => ({
      subject_name: sport.subject_name,
      sequence: sport.sequence,
      params_dict: sport.sports_params.map((param, paramIndex) => ({
        ...param,
        value: assessment[sportIndex].parameters[paramIndex],
      })),
      levels_dict: sport.competition.map((level, levelIndex) => ({
        ...level,
        score: assessment[sportIndex].scores[levelIndex],
      })),
    }));

    return {
      section_mapping: filterData?.section?.id,
      term_id: selectedTerm,
      erp_id: studentData.id,
      remark: remark,
      report_card_json: {
        acad_sessions: [filterData?.branch?.id],
        grades: [filterData.grade?.grade_id],
        term_id: selectedTerm,
        sports: sports,
      },
    };
  };

  const submitAssessment = async () => {
    try {
      const submissionData = prepareSubmissionData();
      await axiosInstance.post('/academic/sports-report-card/', submissionData);
      message.success('Sports assessment submitted successfully');
    } catch (error) {
      console.error('Error submitting assessment:', error);
      message.error('Failed to submit assessment');
    }
  };

  const renderSportContent = (sport, sportIndex) => {
    const columns = [
      {
        title: <span className='th-white th-fw-700'>Parameter</span>,
        dataIndex: 'param_name',
        key: 'param_name',
      },
      {
        title: <span className='th-white th-fw-700'>Evaluation</span>,
        key: 'evaluation',
        render: (_, __, paramIndex) => (
          <Select
            style={{ width: '100%' }}
            placeholder='Select level'
            value={assessment[sportIndex]?.parameters[paramIndex]}
            onChange={(value) => handleParameterChange(sportIndex, paramIndex, value)}
          >
            <Option value='Advanced'>Advanced</Option>
            <Option value='Intermediate'>Intermediate</Option>
            <Option value='Beginner'>Beginner</Option>
          </Select>
        ),
      },
    ];

    return (
      <div>
        <h4 className='mb-3'>Levels Participated</h4>
        <div className='mb-4'>
          {sport.competition.map((level, levelIndex) => (
            <div key={levelIndex} className='mb-2'>
              <Checkbox
                onChange={(e) =>
                  handleLevelChange(sportIndex, levelIndex, e.target.checked)
                }
                checked={assessment[sportIndex]?.levels[levelIndex]}
                className='mr-4'
              >
                {level.level_name}
              </Checkbox>
              {assessment[sportIndex]?.levels[levelIndex] && (
                <InputNumber
                  min={0}
                  value={assessment[sportIndex]?.scores[levelIndex]}
                  onChange={(value) => handleScoreChange(sportIndex, levelIndex, value)}
                  placeholder='Participations'
                  style={{ width: '150px' }}
                />
              )}
            </div>
          ))}
        </div>

        <h4 className='mb-3'>Evaluation Parameters</h4>
        <Table
          columns={columns}
          dataSource={sport.sports_params}
          pagination={false}
          rowKey={(record) => record.id}
        />
      </div>
    );
  };

  return (
    <>
      <div className='mb-4'>
        <div className='pb-2'>Select Term</div>
        <Select
          placeholder='Select term'
          style={{ width: 200 }}
          onChange={(value) => setSelectedTerm(value)}
          value={selectedTerm}
        >
          <Option value='1'>Semester 1</Option>
          <Option value='2'>Semester 2</Option>
        </Select>
      </div>

      {loading ? (
        <div className='text-center py-5'>
          <Spin size='large' />
        </div>
      ) : selectedTerm && data ? (
        <>
          <Collapse className='my-4'>
            {data.map((sport, index) => (
              <Panel
                header={
                  <span className='font-weight-bold'>
                    {sport.subject_name} - {sport.term_name}
                  </span>
                }
                key={index}
              >
                {renderSportContent(sport, index)}
              </Panel>
            ))}
          </Collapse>
          <div className='mb-4'>
            <h4 className='mb-2'>Remark</h4>
            <Input
              value={remark}
              onChange={(e) => setRemark(e.target.value)}
              placeholder='Enter your remark here'
            />
          </div>
          <Button type='primary' onClick={submitAssessment} size='large'>
            Submit Assessment
          </Button>
        </>
      ) : (
        <div className='text-center py-5'>
          Please select a term to view the assessment.
        </div>
      )}
    </>
  );
};

export default SportsMarksUpload;
