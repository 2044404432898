import React, { useState, useEffect } from 'react';
import {
  Drawer,
  Form,
  Input,
  Button,
  Select,
  Switch,
  Space,
  Divider,
  message,
} from 'antd';

const { Option } = Select;

const EditDrawer = ({ visible, onClose, onSubmit, data, type, existingData }) => {
  const [form] = Form.useForm();
  const [isHide, setIsHide] = useState(false);

  useEffect(() => {
    if (visible && data) {
      form.setFieldsValue(data);
      setIsHide(data.is_hide || false);
    }
  }, [visible, data, form]);

  const handleSubmit = (values) => {
    const updatedValues = { ...values, is_hide: isHide };

    if (existingData && Array.isArray(existingData)) {
      const sequenceExists = existingData.some(
        (item) => item.sequence === updatedValues.sequence && item.id !== updatedValues.id
      );

      if (sequenceExists) {
        message.error(
          'This sequence already exists for this category. Please choose a different sequence.'
        );
        return;
      }
    }
    onSubmit(updatedValues, type);
  };

  const handleIsHideChange = (checked) => {
    setIsHide(checked);
  };

  const renderIsHideSwitch = () => (
    <Form.Item label='Hide'>
      <Switch checked={isHide} onChange={handleIsHideChange} />
    </Form.Item>
  );

  const renderSubjectForm = () => (
    <>
      <Form.Item name='id' hidden>
        <Input />
      </Form.Item>
      <Form.Item name='subject_name' label='Subject Name' rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item name='sequence' label='Sequence' rules={[{ required: true }]}>
        <Input type='number' />
      </Form.Item>
      <Form.Item name='term_id' label='Term' rules={[{ required: true }]}>
        <Select>
          <Option value='1'>Semester 1</Option>
          <Option value='2'>Semester 2</Option>
          <Option value='3'>Semester 3</Option>
          <Option value='4'>Semester 4</Option>
        </Select>
      </Form.Item>
      {renderIsHideSwitch()}
    </>
  );

  const renderLevelForm = () => (
    <>
      <Form.Item name='id' hidden>
        <Input />
      </Form.Item>
      <Form.Item
        name='subject_name'
        label='Participation Name'
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item name='sequence' label='Sequence' rules={[{ required: true }]}>
        <Input type='number' />
      </Form.Item>
      {renderIsHideSwitch()}
    </>
  );

  const renderParamForm = () => (
    <>
      <Form.Item name='id' hidden>
        <Input />
      </Form.Item>
      <Form.Item name='param_name' label='Parameter Name' rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item name='sequence' label='Sequence' rules={[{ required: true }]}>
        <Input type='number' />
      </Form.Item>
      {renderIsHideSwitch()}
    </>
  );

  const renderGradeForm = () => (
    <>
      <Form.Item name='id' hidden>
        <Input />
      </Form.Item>
      <Form.Item name='grade_scale' label='Grade Scale' rules={[{ required: true }]}>
        <Select>
          <Option value='1'>L1</Option>
          <Option value='2'>L2</Option>
          <Option value='3'>L3</Option>
          <Option value='4'>L4</Option>
        </Select>
      </Form.Item>
      <Form.Item name='scale_name' label='Scale Name' rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item name='sequence' label='Sequence' rules={[{ required: true }]}>
        <Input type='number' />
      </Form.Item>
      {renderIsHideSwitch()}
    </>
  );

  const getFormContent = () => {
    switch (type) {
      case 'subject':
        return renderSubjectForm();
      case 'level':
        return renderLevelForm();
      case 'param':
        return renderParamForm();
      case 'grade':
        return renderGradeForm();
      default:
        return null;
    }
  };

  return (
    <Drawer
      title={`Edit ${type.charAt(0).toUpperCase() + type.slice(1)}`}
      placement='right'
      onClose={onClose}
      visible={visible}
      width={400}
    >
      <Form form={form} layout='vertical' onFinish={handleSubmit}>
        {getFormContent()}
        <Form.Item>
          <Space>
            <Button onClick={onClose}>Cancel</Button>
            <Button type='primary' htmlType='submit'>
              Submit
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default EditDrawer;
