import React, { useState, useEffect } from 'react';
import { Checkbox, Select, Button, Spin, message, Collapse, Table } from 'antd';
import axiosInstance from 'config/axios';
import Layout from 'containers/Layout';

const { Option } = Select;
const { Panel } = Collapse;

const StudentAssessment = ({
  gradeId,
  acadSessionId,
  studentId,
  sectionId,
  studentList,
  visible,
}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [assessment, setAssessment] = useState({});
  const [selectedTerm, setSelectedTerm] = useState(null);

  useEffect(() => {
    if (visible && selectedTerm) {
      fetchData();
    }
  }, [visible, selectedTerm, acadSessionId, gradeId, studentId, sectionId]);

  useEffect(() => {
    if (!visible) {
      setData([]);
      setAssessment({});
      setSelectedTerm(null);
      setLoading(false);
    }
  }, [visible]);

  const prefillAssessment = (existingData, templateData) => {
    const prefilled = {};
    templateData.forEach((subject, subjectIndex) => {
      prefilled[subjectIndex] = {
        competitions: {},
        parameters: {},
      };

      const existingSubject = existingData.report_card_json.subjects.find(
        (s) => s.subject_name === subject.subject_name
      );

      if (existingSubject) {
        subject.level_details.forEach((level, levelIndex) => {
          const existingLevel = existingSubject.level_dict.find(
            (l) => l.subject_name === level.subject_name
          );
          prefilled[subjectIndex].competitions[levelIndex] = existingLevel
            ? existingLevel.is_checked
            : false;
        });

        subject.subject_params.forEach((param, paramIndex) => {
          const existingParam = existingSubject.params_dict.find(
            (p) => p.param_name === param.param_name
          );
          prefilled[subjectIndex].parameters[paramIndex] = existingParam
            ? existingParam.value
            : '';
        });
      }
    });
    setAssessment(prefilled);
  };

  const initializeAssessment = (subjects) => {
    const initialAssessment = {};
    subjects.forEach((subject, subjectIndex) => {
      initialAssessment[subjectIndex] = {
        competitions: {},
        parameters: {},
      };
      if (subject.level_details) {
        subject.level_details.forEach((comp, compIndex) => {
          initialAssessment[subjectIndex].competitions[compIndex] = false;
        });
      }
      if (subject.subject_params) {
        subject.subject_params.forEach((param, paramIndex) => {
          initialAssessment[subjectIndex].parameters[paramIndex] = '';
        });
      }
    });
    setAssessment(initialAssessment);
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const templateResponse = await axiosInstance.get(
        `/academic/vlc-rc-template/?acad_session=${acadSessionId}&grade=${gradeId}&term_id=${selectedTerm}`
      );

      if (visible) {
        setData(templateResponse.data.result);

        try {
          const existingDataResponse = await axiosInstance.get(
            '/academic/vlc-report-card/',
            {
              params: {
                erp_id: studentId,
                term_id: selectedTerm,
                section_mapping: sectionId,
              },
            }
          );

          if (existingDataResponse.data?.result) {
            prefillAssessment(
              existingDataResponse.data.result,
              templateResponse.data.result
            );
          } else {
            initializeAssessment(templateResponse.data.result);
          }
        } catch (error) {
          console.error('Error fetching existing report card data:', error);
          initializeAssessment(templateResponse.data.result);
        }
      }
    } catch (error) {
      console.error('Error fetching template data:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleCompetitionChange = (subjectIndex, compIndex, attended) => {
    setAssessment((prev) => ({
      ...prev,
      [subjectIndex]: {
        ...prev[subjectIndex],
        competitions: {
          ...prev[subjectIndex].competitions,
          [compIndex]: attended,
        },
      },
    }));
  };

  const handleParameterChange = (subjectIndex, paramIndex, value) => {
    setAssessment((prev) => ({
      ...prev,
      [subjectIndex]: {
        ...prev[subjectIndex],
        parameters: {
          ...prev[subjectIndex].parameters,
          [paramIndex]: value,
        },
      },
    }));
  };

  const prepareSubmissionData = () => {
    const subjects = data.map((subject, subjectIndex) => {
      const params = subject.subject_params.map((param, paramIndex) => ({
        param_name: param.param_name,
        sequence: param.sequence,
        is_hide: param.is_hide,
        value: assessment[subjectIndex].parameters[paramIndex],
      }));

      const paramValues = params
        .map((p) => {
          switch (p.value) {
            case 'Advanced':
              return 1;
            case 'Intermediate':
              return 2;
            case 'Beginner':
              return 3;
            default:
              return 0;
          }
        })
        .filter((v) => v !== 0);

      const average =
        paramValues.length > 0
          ? paramValues.reduce((sum, val) => sum + val, 0) / paramValues.length
          : 0;

      // Find the nearest grade scale
      const gradeScales = subject.grade_details.map((g) => ({
        ...g,
        difference: Math.abs(g.grade_scale - average),
      }));
      const nearestGrade = gradeScales.reduce((prev, curr) =>
        prev.difference < curr.difference ? prev : curr
      );

      return {
        subject_name: subject.subject_name,
        sequence: subject.sequence,
        is_hide: subject.is_hide,
        level_dict: subject.level_details.map((comp, compIndex) => ({
          subject_name: comp.subject_name,
          sequence: comp.sequence,
          is_hide: comp.is_hide,
          is_checked: assessment[subjectIndex].competitions[compIndex],
        })),
        params_dict: params,
        grade_dict: subject.grade_details.map((grade) => ({
          grade_scale: grade.grade_scale,
          scale_name: grade.scale_name,
          sequence: grade.sequence,
          is_hide: grade.is_hide,
          isSelected: grade.grade_scale === nearestGrade.grade_scale,
        })),
      };
    });

    return {
      section_mapping: sectionId,
      term_id: selectedTerm,
      erp_id: studentId,
      report_card_json: { subjects },
    };
  };

  const submitAssessment = async () => {
    try {
      const submissionData = prepareSubmissionData();
      await axiosInstance.post('/academic/vlc-report-card/', submissionData);
      message.success('Assessment submitted successfully');
    } catch (error) {
      console.error('Error submitting assessment:', error);
      message.error('Failed to submit assessment');
    }
  };

  const renderSubjectContent = (subject, subjectIndex) => {
    const columns = [
      {
        title: <span className='th-white th-fw-700'>Parameter</span>,
        dataIndex: 'param_name',
        key: 'param_name',
      },
      {
        title: <span className='th-white th-fw-700'>Evaluation</span>,
        key: 'evaluation',
        render: (_, __, paramIndex) => (
          <Select
            style={{ width: '100%' }}
            placeholder='Select level'
            value={assessment[subjectIndex]?.parameters[paramIndex]}
            onChange={(value) => handleParameterChange(subjectIndex, paramIndex, value)}
          >
            <Option value='Advanced'>Advanced</Option>
            <Option value='Intermediate'>Intermediate</Option>
            <Option value='Beginner'>Beginner</Option>
          </Select>
        ),
      },
    ];

    return (
      <div>
        <h4 className='mb-3 th-18'>Competitions Attended</h4>
        <div className='mb-4'>
          {subject.level_details.map((competition, compIndex) => (
            <Checkbox
              key={compIndex}
              onChange={(e) =>
                handleCompetitionChange(subjectIndex, compIndex, e.target.checked)
              }
              checked={assessment[subjectIndex]?.competitions[compIndex]}
              className='mr-4 mb-2'
            >
              {competition.subject_name}
            </Checkbox>
          ))}
        </div>

        <h4 className='mb-3 th-18'>Evaluation Parameters</h4>
        <Table
          columns={columns}
          dataSource={subject.subject_params}
          pagination={false}
          rowKey={(_, index) => index}
        />
      </div>
    );
  };

  return (
    <>
      <div className=''>
        <div className='pb-2'>Select Term</div>
        <Select
          placeholder='Select term'
          style={{ width: 200 }}
          onChange={(value) => setSelectedTerm(value)}
          value={selectedTerm}
        >
          <Option value='1'>Semester 1</Option>
          <Option value='2'>Semester 2</Option>
        </Select>
      </div>

      {loading ? (
        <div className='text-center py-5'>
          <Spin size='large' />
        </div>
      ) : selectedTerm && data?.length > 0 ? (
        <>
          <Collapse className='my-4'>
            {data?.map((subject, index) => (
              <Panel
                header={
                  <span className='font-weight-bold'>
                    {subject.subject_name} - {subject.term_name}
                  </span>
                }
                key={index}
              >
                {renderSubjectContent(subject, index)}
              </Panel>
            ))}
          </Collapse>
          <Button type='primary' onClick={submitAssessment} size='large'>
            Submit Assessment
          </Button>
        </>
      ) : (
        <div className='text-center py-3'>
          {selectedTerm
            ? 'Config is not created for the selected term.'
            : 'Please select a term to view the assessment.'}
        </div>
      )}
    </>
  );
};

export default StudentAssessment;
