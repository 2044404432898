import React, { useEffect } from 'react';
import { Drawer, Form, Input, Switch, Space, Button } from 'antd';

const EditDrawer = ({ visible, onClose, onSubmit, data, type }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (visible && data) {
      form.setFieldsValue(data);
    }
  }, [visible, data, form]);

  const handleSubmit = (values) => {
    onSubmit(values, type);
  };

  const renderSportForm = () => (
    <>
      <Form.Item name='id' hidden>
        <Input />
      </Form.Item>
      <Form.Item name='subject_name' label='Sport Name' rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item name='sequence' label='Sequence' rules={[{ required: true }]}>
        <Input type='number' />
      </Form.Item>
    </>
  );

  const renderCompetitionForm = () => (
    <>
      <Form.Item name='id' hidden>
        <Input />
      </Form.Item>
      <Form.Item name='level_name' label='Level Name' rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item name='sequence' label='Sequence' rules={[{ required: true }]}>
        <Input type='number' />
      </Form.Item>
    </>
  );

  const renderParamForm = () => (
    <>
      <Form.Item name='id' hidden>
        <Input />
      </Form.Item>
      <Form.Item name='param_name' label='Parameter Name' rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item name='sequence' label='Sequence' rules={[{ required: true }]}>
        <Input type='number' />
      </Form.Item>
    </>
  );

  const getFormContent = () => {
    switch (type) {
      case 'sport':
        return renderSportForm();
      case 'competition':
        return renderCompetitionForm();
      case 'param':
        return renderParamForm();
      default:
        return null;
    }
  };

  return (
    <Drawer
      title={`Edit ${type.charAt(0).toUpperCase() + type.slice(1)}`}
      placement='right'
      onClose={onClose}
      visible={visible}
      width={400}
    >
      <Form form={form} layout='vertical' onFinish={handleSubmit}>
        {getFormContent()}
        <Form.Item>
          <Space>
            <Button onClick={onClose}>Cancel</Button>
            <Button type='primary' htmlType='submit'>
              Submit
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default EditDrawer;
